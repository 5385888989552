@import '../../styles/partials/variables';

.search-page {
  &__close-btn {
    font-size: 16px;
    -webkit-appearance: none;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
  &__title-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    padding: 14px 16px;
    a {
      color: $base-color;
      text-decoration: none;
      font-weight: 600;
    }
  }
  &__wrapper {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  &__input-container {
    position: relative;
    padding: 0 16px;
    background:#fff;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  }
  &__input {
    border: none;
    background: transparent;
    font-size: 16px;
    line-height: 22px;
    padding: 16px;
    color: rgb(74,74,74);
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  &__results {
    &-not-found {
      font-weight: 600;
      font-size: 12px;
      color: rgb(74, 74, 74);
      text-align: center;
      padding-top: 16px;
      margin: 0 auto;
    }
    &-wrapper {
      background-color: white;
      max-width: 480px;
      height: 100vh;
      width: 100%;
      margin: 0 auto;
    }
  }
}
