.gopayLayout {
  margin-bottom: 8px;
  margin-right: -8px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;

  &__title {
    font-size: 12px;
    color: #4a4a4a;
    margin: 0;
  }

  &__logo {
    max-width: 55px;
  }

  &__connect {
    align-self: center;
    padding: 5px;
    border-radius: 5px;
    background: white;
    z-index: 2;

    &-balance {
      background: white;
      border: 1px solid #E3E3E3;
      border-radius: 5px;
      padding: 8px;
      font-weight: bold;
      font-size: 12px;
    }

    &-btn {
      background-color: #ED3833;
      padding: 5px;
      font-size: 10px;
      border-radius: 5px;
      border: none;
      color: #ffffff;
      height: 30px;
      font-weight: 600;
    }
  }
}

.unlink {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 16px;

  &-title {
    color: #3a3a3a;
    font-weight: bold;
    font-size: 18px;
  }

  &-btn {
    align-self: center;
    background-color: #50B058;
    border: 1px solid #50B058;
    border-radius: 5px;
    width: 118px;
    height: 30px;
    color: #ffffff;
    font-weight: bold;
  }
}
