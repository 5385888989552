.gopayLink{
  display: flex;
  min-height: 100vh;
  align-content: flex-start;
  flex-flow: column wrap;
  background: #fff;
  max-width: 480px;
  margin: 0 auto;

  &__top {
    padding: 16px 16px 20px;
    border-bottom:1px solid #E9E9E9;
    &-title {
      font-size: 18px;
      color: #3a3a3a;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    &-subTitle {
      color: #3a3a3a;
      font-size: 16px;
    }
  }

  &__bottom {
    padding: 20px 16px;

    &-text {
      color: #3a3a3a;
      font-size: 16px;
    }

    &-number {
      display: flex;
      flex-flow: row wrap;
      margin-top: 30px;

      &__left {
        background-color: #F1F1F1;
        border: 1px solid #D7D7D7;
        border-radius: 5px;
        width: 75px;
        display: flex;
        height: 30px;
        align-items: center;
        padding: 8px;

        &-flag {
          width: 23px;
          max-height: 16px;
          margin-right: 4px;
        }
      }
    }
    &-cursor {
      position: relative;
    }
    &-cursor	i {
      position: absolute;
      width: 1px;
      height: 80%;
      background-color: gray;
      left: 18px;
      top: 10%;
      animation-name: blink;
      animation-duration: 800ms;
      animation-iteration-count: infinite;
      opacity: 1;
    }

    &-cursor input:focus + i {
      display: none;
    }


    &-form {
      border: none;
      height: 30px;
      margin-left: 16px;
      font-size: 16px;
      width: 100%;

      &:focus {
        outline-color: transparent !important;
      }
    }

    &-fix {
      position: fixed;
      bottom: 0;
      display: flex;
      max-width: 480px;
      width: 100%;
    }
    &-code {
      margin-top: auto;
      display: flex;
      max-width: 480px;
      width: 100%;
    }
  }
  @keyframes blink {
    from { opacity: 1; }
    to { opacity: 0; }
  }
}

.verification {

  &__header {
    border-bottom: 1px solid #e9e9e9;
    padding: 8px 16px;
  }

  &__content {
    min-height: 100vh;
    padding: 36px 16px;

    &-title {
      font-size: 18px;
      color: #3a3a3a;
      margin-top: 32px;
      margin-bottom: 10px;
    }

    &-info {
      margin-top: 10px;

      &-text {
        margin-left: 16px;
      }
    }
  }

  &__separate {
    margin: 25px -16px 20px;
    border: 1px solid #e9e9e9;
  }

  &__phone {
    margin-top: 20px;
    margin-bottom: 25px;

    &-number {
      font-weight: bold;
      margin-left: 16px;
    }
  }

}
