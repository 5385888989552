.carousel {
  overflow: hidden;
  margin: 0 -16px;
  a {
    display: block!important;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display:block;
  }
}
