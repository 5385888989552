.wrapper {
  max-width: 480px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}
.container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.subtitle {
  color: #4D4D4D;
  font-size: 14px;
  line-height: 18px;
}
.title {
  font-size: 18px;
  height: 24px;
  color: #4A4A4A;
  margin: 0 0 24px;
}
