body {
  background: #fff;
}
.container {
  background: #fff;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  min-height: calc(100vh - 60px);
}
.donationLabel {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
}
.section {
  margin: 10px 0 72px;
  padding: 0 16px;
}
.gopaySection{
  padding: 0 16px;
  min-height: 125px;
}
.sectionDonationBtn {
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 72px;
  max-width: 480px;
  width: 100%;
  background-color: rgba(247, 247, 247, 0.8);
  box-shadow: rgba(157, 157, 157, 0.5) 0px -2px 3px -2px;
}

.currency {
  position: absolute;
  left: 16px;
  top: 14px;
}
.donationInput {
  position: relative;
  input {
    border: none;
    background-color: #F2F2F5;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    padding: 15px;
    color: #3A3A3A;
    font-family: inherit;
    font-weight: 300;
    height: 50px;
    text-align: right;
    &:focus {
      outline: none;
    }
  }
}
.donationInputError {
  color: red;
  font-size: 14px;
  display: block;
  padding: 5px;
  margin-top: 5px;
}

.ssoToken {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.ssoCaption {
  font-size: 14px;
  color: #3A3A3A;
}

.btnSSO {
  background-color: #00AEEF;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 12px;
  border: none;
  padding: 6px 10px;
}

.btnToken {
  @extend .btnSSO;
  background-color: #C9C9C9;
  color: #989898;
}

.btnCancel {
  @extend .btnSSO;
  background-color: #FFFFFF;
  color: #00AEEF;
  border: 1px solid #00AEEF;
}

.gopayImg {
  width: 77px;
}

.gopayAccount {
  margin-bottom: 12px;

  &--name {
    margin: 0;
  }
  &--phone {
    color: #989898;
  }
}

.gopayBalance {
  margin-left: -30%;
  color: #3A3A3A;
  font-weight: 600;
  font-size: 14px;
}

.gopayInsufficient {
  color: #F80505;
  font-size: 12px;
}

