.donationLayout {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  background-color: #FFFFFF;
  max-width: 480px;
  flex-flow: column;
  overflow-x: hidden;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  flex-flow: column;
  margin-top: 60px;

  &-img {
    display: flex;
    align-self: center;
  }
}

.imgDownload {
  text-align: center;
  margin: 0 8px;

  img {
    width: 100%;
  }
}

.textTitle {
  padding: 0 48px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.textSubtitle {
  padding: 0 56px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.textRefund {
  padding: 0 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  margin: 0;
}

.textAmount {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 110px;
}

.customDivider{
  background: #F5F5F5;
  height: 5px;
  max-width: 480px;
  margin: 20px -48px;
}

.share {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 480px;
  &__info{
    text-align: center;
    margin: 0 0 15px;
    color: #4a4a4a;
  }
}

.shareButton {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.waShare {
  display: inline-block;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 48%;
  font-size: 14px !important;
  background-color: #25d366 !important;
  color: white !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.fbShare {
  display: inline-block;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 48%;
  font-size: 14px !important;
  background-color: #3B5998 !important;
  color: white !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.back{
  margin: 10px 0 16px;
  &__btn {
    width: 100%;
    border: 1px solid #50B058;
    padding: 10px;
    color: #50B058;
    font-size: 14px;
    background: #fff;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    border: none;
    &:focus{
      outline: none
    }
  }
}

.shareWrapper {
  height: 100%;
  display: inline-flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
}

.shareImg {
  max-width: 15px;
  max-height: 15px;
  margin-right: 6px;
}

.shareText {
  align-items: center;
  display: flex;
  align-self: center;

  @media (max-width: 480px) {
    padding-top: 4px;
  }
}
