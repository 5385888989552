.nominalOptions {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  align-content: center;
  color: rgb(74, 74, 74);
  flex-flow: wrap;
  margin: 10px -5px;
}
.section {
  margin: 10px 0;
  padding: 0 16px;
}
.currency {
  position: absolute;
  left: 16px;
  top: 14px;
}
.donationInput {
  position: relative;
  input {
    border: none;
    background-color: #F2F2F5;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    padding: 15px;
    color: #3A3A3A;
    font-family: inherit;
    font-weight: 300;
    height: 50px;
    text-align: right;
    &:focus {
      outline: none;
    }
  }
}
.donationInputError {
  color: red;
  font-size: 14px;
  display: block;
  padding: 5px;
  margin-top: 5px;
}

.donationLabel {
  color: #989898;
  font-size: 14px;
}
