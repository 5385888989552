.imgTop {
  width: 100%;
  position: absolute;
  text-align: center;
}

.bottom {
  max-width: 275px;
  margin: 60px auto;

  &-title{
    text-align: center;
    margin-bottom: 18px;
  }

  &-btn {
    background-color: #00AEEF;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    height: 35px;
    width: 100%;
    border: none;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
  }
}
