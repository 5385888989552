.searchInput {
  &__input {
    text-align: left;
    cursor: text;
    display: flex;
    text-decoration: none;
    color: #969698;
    background: #F2F2F5;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    align-items: center;

    span {
      margin-left: 10px;
    }
    &:focus {
      outline: none;
    }
  }
}
