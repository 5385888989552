@import "partials/sanitize.scss";
@import "partials/variables.scss";
@import "partials/slick.scss";
@import "partials/slick-theme.scss";

* {
  box-sizing: border-box;
}

a {
  &:focus,
  &:active {
    outline: none
  }
}

body {
  color: #4A4A4A;
  font-family: 'maison_neuebook', helvetica, sans-serif;
  background: #f7f7f7;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

button {
  &:focus {
    outline: none
  }
}

section {
  margin-bottom: 25px;
  h3 {
    font-family: 'maison_neuedemi','helvetica', sans-serif;
    font-size: 16px;
    line-height: 21px;
    color: #4A4A4A;
    margin: 4px 0;
  }
  .subtitle {
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
}
section.fullwidth {
  margin: 0 -16px;
}

.slick-slide {
  // padding-right: 13px;
}
.slick-initialized .slick-slide {
  display: block;
}

.Modal-homepage {
  position: absolute;
  padding: 13px;
  top: 103px;
  left: 16px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  width: 213px;
  // height: 203px;

  &__title {
    margin: 16px 0 8px;
    font-weight: bold;
    font-size: 16px;
    font-weight: bold;
  }

  &__subTitle {
    margin: 0;
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }

  &::after {
    content: " ";
    position: absolute;
    right: -6px;
    top: 11px;
    border-top: 5px solid transparent;
    border-right: none;
    border-left: 6px solid #ffffff;
    border-bottom: 5px solid transparent;
  }

  @media (max-width:320px) {
    width: 173px;
    left: 6px;
  }
  @media (min-width:410px) and (max-width:480px) {
    width: 250px;
  }
}

.Overlay-homepage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  max-width: 480px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.Modal-unlink {
  position: absolute;
  padding: 13px;
  bottom: 0;
  background: white;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  width: 100%;

  &:focus {
    outline: none;
  }
}

.Overlay-unlink {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  max-width: 480px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.Modal-ErrorLink {
  position: absolute;
  padding: 16px 16px 24px;
  top: 135px;
  background: white;
  border-radius: 9px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  left: 16px;
  right: 16px;

  &:focus {
    outline: none;
  }
}

.Overlay-ErrorLink {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  max-width: 480px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}

.Modal-gopayLink {
  margin: 0 auto;
  max-width: 480px;
  top: 0;

  &:focus {
    outline: none;
  }
}

.Overlay-gopayLink {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  max-width: 480px;
  background-color: white;
  margin: 0 auto;
}

.Modal-contribute {
  position: absolute;
  top: 65px;
  left: 16px;
  right: 16px;
  background: white;
  border-radius: 9px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  height: 240px;
  display: flex;
  flex-flow: column wrap;

  &:focus {
    outline: none;
  }


  @media (max-width: 320px) {
    max-width: 280px;
    left: 20px;
    right: 21px;
  }

  @media (min-width: 421px) {
    max-width: 380px;
    left: 8%;
  }
}

.Overlay-contribute {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  max-width: 480px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.Modal-ErrorAccount {
  position: absolute;
  top: 40px;
  left: 16px;
  right: 16px;
  background: white;
  border-radius: 9px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  height: 240px;
  display: flex;
  flex-flow: column wrap;

  &:focus {
    outline: none;
  }


  @media (max-width: 320px) {
    max-width: 280px;
    left: 20px;
    right: 21px;
  }

  @media (min-width: 421px) {
    max-width: 380px;
    left: 8%;
  }
}

